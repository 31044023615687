<template>
  <div class="product-silder" v-if="suppliers && suppliersLength > 0">
    <!-- if there is suppliers  -->
    <div class="suppliers pt-2" >
      <span class="product-info">
        <h4 class="text-dark">{{ $t("home.suppliers") }}</h4>
      </span>
      <div class="container mb-1">
        <div class="d-flex justify-content-end">
          <router-link to="/suppliers" class="showAllLink">
            {{ $t("home.showAll") }}
          </router-link>
        </div>
      </div>
      <!-- data loading  -->
      <b-row v-if="loading" class="px-5">
        <b-col lg="3" sm="6" v-for="x in 10" :key="x">
          <b-skeleton-img></b-skeleton-img>
          <b-card>
            <b-skeleton animation="fade" width="60%" class="border-none"></b-skeleton>
            <b-skeleton animation="fade" width="85%" class="border-none"></b-skeleton>
          </b-card>
        </b-col>
      </b-row>
      <!-- data comes from backend  -->
      <VueSlickCarousel
        v-bind="settings"
        v-if="!loading && suppliers && suppliers.length"
      >
        <div v-for="(supplier, index) in suppliers" :key="index">
          <router-link
            :to="`/suppliers/${supplier.id}`"
            class="d-flex justify-content-center align-items-center text-center"
          >
            <div class="" v-if="supplier.image_path !== null">
              <b-img
                :src="supplier.image_path"
                class="supplier-image"
                alt="supplier image"
              >
              </b-img>
            </div>
            <div
              class=""
              v-else-if="
                supplier.image_path == null && supplier.product.image_path !== null
              "
            >
              <b-img :src="supplier.product.image_path" class="offer-image"> </b-img>
            </div>
          </router-link>
        </div>
      </VueSlickCarousel>
    </div>
  </div>
</template>

<script>
import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
// optional style for arrows & dots
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";
import globalAxios from "@/services/global-axios";
import auth from "@/services/auth";
export default {
  components: {
    VueSlickCarousel,
    // Countdown,
  },
  data() {
    return {
      settings: {
        dots: false,
        infinite: true,
        arrows: true,
        speed: 500,
        slidesToShow: 6,
        slidesToScroll: 1,
        swipeToSlide: true,
        autoplay: true,

        responsive: [
          {
            breakpoint: 1191,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 1,
            },
          },
          {
            breakpoint: 820,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1,
            },
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            },
          },
        ],
      },
      suppliers: null,
      suppliersLength: null,
      loading: false,
      sliders: null,
      deadline: null,
    };
  },
  methods: {
    /**
     * @vuese
     *  get Suppliers
     */
    getSuppliers() {
      this.loading = true;
      return globalAxios
        .get(`suppliers`)
        .then((resp) => {
          this.suppliers = resp.data.items.data;
          this.suppliersLength = resp.data.items.data.length;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    /**
     * @vuese
     *  get home deadline counter
     */
    getHomeDeadline() {
      auth
        .getHomeDeadline()
        .then((res) => {
          // console.log("slide data", res);
          this.sliders = res.data.items.deals.data;
          this.deadline = res.data.items.timer_date;
          // console.log("this.deadline" , moment(new Date(res.data.items.timer_date)).format(
          //   "YYYY-MM-DD HH:mm:ss"
          // ));
        })
    },
  },
  created() {
    this.getSuppliers();
  },
  mounted() {
    this.getHomeDeadline();
  },
  computed: {
    /**
     *  check if slider has data
     */
    slidersLength() {
      return this.sliders ? this.sliders.length : 0;
    },
  },
};
</script>

<style lang="scss" scoped>
/**
        *  component style
      */
.product-silder {
  background-color: #f9f8f5;
  text-align: center;
  padding: 20px 5px;

  .product-info {
    padding-bottom: 20px;

    small {
      color: $main-color;
      font-size: 12px;
      text-transform: uppercase;
    }
  }

  .img-suplier {
    opacity: 1;
    cursor: pointer;
    transition: all 0.5s ease-in-out;
    display: block;
    text-align: center;

    &:hover {
      opacity: 0.75;
    }
  }
}

.slick-current {
  img {
    opacity: 1;
  }
}

.supplier-image {
  height: 150px;
  width: 150px;
  border-radius: 50%;
  transition: all 0.3s ease-in-out;
  opacity: 1;

  &:hover {
    opacity: 0.5;
  }
}
</style>
