export default {
  login: "تسجيل الدخول",
  WelcomeAgain: "مرحبا بك مجددا ",
  fogetPassword: "نسيت كلمة المرور ؟",
  DontHave: "ليس لديك حساب ؟",
  createAccount: "انشاء حساب",
  LoginSocial: "أو الاستمرار بواسطة",
  loginNav: "تسجيل الدخول",
  welcome: "مرحبا",
  logout: "تسجيل خروج",
  reset: "إعادة كلمة المرور",
  yourEmail: "بريدك الإلكتروني",
  thankYou: "شكراً لك",
  invalidEmail: "بريد خاطئ",
  resetPassword: "إعادة كلمة المرور",
  log1: "أنت على وشك الحصول على طلبك!",
  log2: "هل تريد أن يكون لديك حساب مسجل في Humhumm؟",
  logBtn1: " نعم ، أريد التسجيل",
  logBtn2: "لا ، المتابعة بدون تسجيل",
  actionComplete: "من فضلك اكمل العملية",
  wholeSaleBuyer:'المشتري بالجملة',
  retailBuyer:'عملاء التجزئة',
  wholeSaleClients:'عملاء الجمله',
  characters:"أحرف",
  specialCharacter:"من الرموز",
  uppercaseLetter:"حرف كبير",
  lowercaseLetter:"حرف صغير",
  number:"رقم"

};
