<template>
  <div :class="{ 'dynamic-form-holder': cartPage }">
    <div class="newForm-holder">
      <div v-for="(input, index) in dynamicInputs" :key="index" class="newForm">
        <b-form-group>
          <div v-if="input.dynamic_input.type == 'checkbox'">
            <!-- <b-form-checkbox
              v-model="form[input.dynamic_input.uuid]"
              :value="true"
              :unchecked-value="false"
              disabled
            >
              <span>{{
                $i18n.locale == "en"
                  ? input.dynamic_input.title_en
                  : input.dynamic_input.title_ar
              }}</span>
            </b-form-checkbox> -->
            <label :for="input.dynamic_input.uuid">{{
              $i18n.locale == "en"
                ? input.dynamic_input.title_en
                : input.dynamic_input.title_ar
            }}</label>
            <p>{{form[input.dynamic_input.uuid] == true ? $t('yes') : $t('no')}}</p>
          </div>
          <div v-if="input.dynamic_input.type == 'text'">
            <label :for="input.dynamic_input.uuid">{{
              $i18n.locale == "en"
                ? input.dynamic_input.title_en
                : input.dynamic_input.title_ar
            }}</label>
            <p v-html="form[input.dynamic_input.uuid]"></p>
          </div>

          <div v-if="input.dynamic_input.type == 'select'">
            <label :for="input.dynamic_input.uuid">{{
              $i18n.locale == "en"
                ? input.dynamic_input.title_en
                : input.dynamic_input.title_ar
            }}</label>
            <p v-html="form[input.dynamic_input.uuid]"></p>
          </div>
          <div v-if="input.dynamic_input.type == 'file'">
            <div class="row">
              <div
                :class="{
                  'col-md-6 col-sm-12': input.dynamic_input.values,
                  'col-12': !input.dynamic_input.values,
                }"
              >
                <label :for="input.dynamic_input.uuid">{{
                  $i18n.locale == "en"
                    ? input.dynamic_input.title_en
                    : input.dynamic_input.title_ar
                }}</label>
                <div
                  v-if="
                    form[input.dynamic_input.uuid] &&
                    filePreviews[input.dynamic_input.uuid]
                  "
                >
                  <div
                    v-if="isImage(filePreviews[input.dynamic_input.uuid])"
                    class="d-flex flex-column"
                  >
                    <img
                      :src="filePreviews[input.dynamic_input.uuid]"
                      class="img-preview border-main"
                    />
                    <b-button
                      @click="
                        downloadWithAxios(
                          input.value_path,
                          $i18n.locale == 'en'
                            ? input.dynamic_input.title_en
                            : input.dynamic_input.title_ar
                        )
                      "
                      class="w-fit-content mt-2 btn border-main"
                    >
                    {{ $t("profile.download") }}
                    </b-button>
                  </div>
                  <div v-else>
                    <b-button
                      @click="downloadFile(input.value_path)"
                      class="w-fit-content mt-2 btn border-main"
                    >
                    {{ $t("profile.download") }}
                    </b-button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </b-form-group>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  props: ["dynamicInputs", "loading"],
  data() {
    return {
      currentRoute: this.$route,
      documentsPage: false,
      cartPage: false,
      form: {},
      filePreviews: {},
    };
  },
  mounted() {
    this.prepairInputs();
  },
  watch: {
    dynamicInputs: {
      handler() {
        this.prepairInputs();
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    filterData() {
      const formData = this.getFilteredFormData();
      this.$emit("filerDynamicInputs", formData);
    },
    prepairInputs() {
      this.dynamicInputs.forEach((input) => {
        if (!this.form[input.dynamic_input.uuid]) {
          this.$set(this.form, input.dynamic_input.uuid, input.value || null);
        }
        if (input.dynamic_input.type == "checkbox") {
          this.$set(
            this.form,
            input.dynamic_input.uuid,
            input.value === "true"
          );
        }
        if (input.dynamic_input.type == "file") {
          if (input.value_path) {
            this.$set(
              this.filePreviews,
              input.dynamic_input.uuid,
              input.value_path
            );
          }
        }
      });
    },
    isImage(url) {
      const imageExtensions = ["jpg", "jpeg", "png", "gif"];
      const extension = url.split(".").pop().split("?")[0];
      return imageExtensions.includes(extension.toLowerCase());
    },
    async downloadFile(url) {
      try {
        const response = await axios.get(url, {
          responseType: "blob",
        });
        const blob = new Blob([response.data]);
        const link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.download = url.split("/").pop().split("?")[0];
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(link.href);
      } catch (error) {
        console.error("Error downloading the file", error);
      }
    },

    forceFileDownload(response, title) {
      console.log(title);
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", title);
      document.body.appendChild(link);
      link.click();
    },
    downloadWithAxios(url, title) {
      axios({
        method: "get",
        url,
        responseType: "arraybuffer",
      })
        .then((response) => {
          this.forceFileDownload(response, title);
        })
        .catch(() => console.log("error occured"));
    },
    getFilteredFormData() {
      const filteredData = {};
      Object.keys(this.form).forEach((key) => {
        const input = this.dynamicInputs.find(
          (input) => input.dynamic_input.uuid === key
        );
        if (input && input.dynamic_input.type !== "file") {
          filteredData[key] = this.form[key];
        }
      });
      return filteredData;
    },
  },
};
</script>

<style lang="scss" scoped>
.dynamic-form-holder {
  .dynamic-input,
  .b-custom-control-lg .custom-file-label {
    border: none;
    outline: none;
    box-shadow: none;
    border-radius: 15px !important;
    &:focus {
      box-shadow: 0 0 0 0.1rem #6e6e6e;
    }
  }
}
.img-preview {
  max-width: 100%;
  height: auto;
  margin-top: 10px;
  width: 300px;
  height: 300px;
}
.w-fit-content {
  width: fit-content !important;
}
.newForm {
  margin-bottom: 30px;
  width: calc(100% / 3) !important;
}
.newForm-holder{
  display: flex !important;
  flex-wrap: wrap;
}
</style>
