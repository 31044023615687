var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"profile-menu"},[(_vm.opened)?_c('div',{staticClass:"d-flex justify-content-center align-items-center flex-column"},[_c('img',{staticClass:"company-img",attrs:{"src":_vm.buyerUserData.image_path,"alt":"","srcset":""}}),(_vm.buyerUserData.company_name_en)?_c('h5',{staticClass:"my-3"},[_vm._v(" "+_vm._s(_vm.buyerUserData.company_name_en)+" ")]):(_vm.buyerUserData.company_name_ar)?_c('h5',{staticClass:"my-3"},[_vm._v(" "+_vm._s(_vm.buyerUserData.company_name_ar)+" ")]):_c('h5',{staticClass:"my-3"},[_vm._v(" "+_vm._s(_vm.buyerUserData.company_name_en)+" ")])]):_vm._e(),(_vm.buyerUserData && _vm.buyerUserData.profile_percentage !== 100 && _vm.opened)?_c('div',{staticClass:"my-2 px-3"},[_c('h5',[_vm._v(_vm._s(_vm.$t("profile.completeRate")))]),_c('b-progress',{staticClass:"progress-rate",class:{ 'mr-2': _vm.$i18n.locale == 'en', 'ml-2': _vm.$i18n.locale == 'ar' },attrs:{"value":_vm.buyerUserData.profile_percentage,"max":"100","show-progress":"","animated":"","variant":"danger"}})],1):_vm._e(),(!_vm.socialLogin)?_c('ul',[_vm._l((_vm.buyerLinks),function(link,index){return _c('li',{key:index,class:{
        'd-none':
          link.name.trim() === 'Subscribe to the newsletter' &&
          _vm.buyerUserData.register_mailing_list,
      },on:{"click":_vm.closeSideBar}},[_c('router-link',{attrs:{"to":link.to}},[(link.name == _vm.$t('profile.b2bFav'))?_c('img',{staticClass:"standing-order-sign",attrs:{"src":require("@/assets/images/new-design/standing-order-sign.png"),"alt":"standing-order-sign"}}):_c('font-awesome-icon',{attrs:{"icon":("fa-solid fa-" + (link.iconName)),"size":"1x"}}),(_vm.opened)?_c('span',[_vm._v(_vm._s(link.name))]):_vm._e(),(_vm.opened)?_c('span',[(
              _vm.userBades &&
              _vm.userBades.orders &&
              link.name.trim() == _vm.$t('profile.ordersLists')
            )?_c('span',{staticClass:"side-number"},[_vm._v(_vm._s(_vm.userBades.orders))]):_vm._e(),(
              _vm.userBades &&
              _vm.userBades.returns &&
              link.name.trim() === _vm.$t('profile.returnRequests')
            )?_c('span',{staticClass:"side-number"},[_vm._v(_vm._s(_vm.userBades.returns))]):_vm._e(),(
              _vm.userBades &&
              _vm.userBades.client_messages &&
              link.name.trim() === _vm.$t('profile.supplierCorrespondence')
            )?_c('span',{staticClass:"side-number"},[_vm._v(_vm._s(_vm.userBades.client_messages))]):_vm._e(),(
              _vm.userBades && _vm.userBades.rfqs && link.name.trim() === _vm.$t('profile.quotations')
            )?_c('span',{staticClass:"side-number"},[_vm._v(_vm._s(_vm.userBades.rfqs))]):_vm._e()]):_vm._e()],1)],1)}),_c('li',[_c('div',{staticClass:"logout",on:{"click":function($event){return _vm.logout()}}},[_c('span',{staticClass:"logout-sign"},[_c('font-awesome-icon',{attrs:{"icon":"fa-solid fa-power-off"}})],1),(_vm.opened)?_c('span',{staticClass:"mx-2"},[_vm._v(" "+_vm._s(_vm.$t("login.logout"))+" ")]):_vm._e()])])],2):_c('ul',[_vm._l((_vm.SocialLinks),function(link,index){return _c('li',{key:index,class:{
        'd-none':
          link.name.trim() === 'Subscribe to the newsletter' &&
          _vm.buyerUserData.register_mailing_list,
      },on:{"click":_vm.closeSideBar}},[_c('router-link',{attrs:{"to":link.to}},[(link.name == _vm.$t('profile.b2bFav'))?_c('img',{staticClass:"standing-order-sign",attrs:{"src":require("@/assets/images/new-design/standing-order-sign.png"),"alt":"standing-order-sign"}}):_c('font-awesome-icon',{attrs:{"icon":("fa-solid fa-" + (link.iconName))}}),(_vm.opened)?_c('span',[_vm._v(_vm._s(link.name))]):_vm._e(),(_vm.opened)?_c('span',[(
              _vm.userBades &&
              _vm.userBades.orders &&
              link.name.trim() == _vm.$t('profile.ordersLists')
            )?_c('span',{staticClass:"side-number"},[_vm._v(_vm._s(_vm.userBades.orders))]):_vm._e(),(
              _vm.userBades &&
              _vm.userBades.returns &&
              link.name.trim() === _vm.$t('profile.returnRequests')
            )?_c('span',{staticClass:"side-number"},[_vm._v(_vm._s(_vm.userBades.returns))]):_vm._e(),(
              _vm.userBades &&
              _vm.userBades.client_messages &&
              link.name.trim() === _vm.$t('profile.supplierCorrespondence')
            )?_c('span',{staticClass:"side-number"},[_vm._v(_vm._s(_vm.userBades.client_messages))]):_vm._e(),(
              _vm.userBades && _vm.userBades.rfqs && link.name.trim() === _vm.$t('profile.quotations')
            )?_c('span',{staticClass:"side-number"},[_vm._v(_vm._s(_vm.userBades.rfqs))]):_vm._e()]):_vm._e()],1)],1)}),_c('li',[_c('div',{staticClass:"logout",on:{"click":function($event){return _vm.logout()}}},[_c('span',{staticClass:"logout-sign"},[_c('font-awesome-icon',{attrs:{"icon":"fa-solid fa-power-off"}})],1),(_vm.opened)?_c('span',{staticClass:"mx-2"},[_vm._v(" "+_vm._s(_vm.$t("login.logout"))+" ")]):_vm._e()])])],2)])}
var staticRenderFns = []

export { render, staticRenderFns }