import { render, staticRenderFns } from "./B2bTab.vue?vue&type=template&id=62c27b59&scoped=true&"
import script from "./B2bTab.vue?vue&type=script&lang=js&"
export * from "./B2bTab.vue?vue&type=script&lang=js&"
import style0 from "./B2bTab.vue?vue&type=style&index=0&id=62c27b59&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "62c27b59",
  null
  
)

export default component.exports