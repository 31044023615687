<template>
  <!-- otp verify page  -->
  <section class="otp-verification">
    <b-alert variant="danger" show v-if="massageErr">
      {{ massageErr }}
    </b-alert>

    <div class="otp-form mt-5">
      <h1>{{ $t("login.welcome") }}</h1>
      <h4>{{ $t("register.pleaseVerification") }}</h4>
      <h5>{{ $t("register.codeSent") }}</h5>
      <div class="block-header my-3">
        {{ $t("register.verificationAccount") }}
      </div>
      <div class="" v-if="registerOTP">
        <form @submit.prevent="otpVerification()">
          <b-form-group>
            <label for="l-name">otp</label>
            <span class="requried">*</span>
            <b-form-input id="l-name" v-model="form.code" required />
          </b-form-group>
          <b-button type="submit" variant="danger" class="bg-main">
            <font-awesome-icon icon="fas fa-lock" />
            {{ $t("register.verification") }}
          </b-button>
        </form>
        <div class="my-3 resend" @click="resendCode()" v-if="!resend">
          {{ $t("register.notArrive") }}
        </div>
      </div>
      <div class="" v-else>
        <form @submit.prevent="otpChangingVerification()">
          <b-form-group>
            <label for="l-name">otp</label>
            <span class="requried">*</span>
            <b-form-input id="l-name" v-model="form.code" required />
          </b-form-group>
          <b-button type="submit" variant="danger">
            <font-awesome-icon icon="fas fa-lock" />
            {{ $t("register.verification") }}
          </b-button>
        </form>
        <div class="my-3 resend" @click="resendCode()" v-if="!resend">
          {{ $t("register.notArrive") }}
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import auth from "@/services/auth";

export default {
  data() {
    return {
      form: {
        code: "",
        uuid: this.$route.query.uuid ? this.$route.query.uuid : this.buyerUserData.uuid,
        email: this.$route.query.email ? this.$route.query.email : this.buyerUserData.email,
      },
      massageErr: "",
      registerOTP: true,
      resend: this.$route.query.resend ? this.$route.query.resend : null,
      smsAvailability: null
    };
  },
  async mounted() {
    if (!this.buyerUserData) {
      this.$router.push('/')
    }
    try {
      if (!this.$route.query.type) {
        await this.emailVerify();
      }
    } catch (error) {
      console.log(error);
    }
    try {
      if (this.$route.query.type) {
        this.registerOTP = false;
        await this.emailChangingVerify();
      }
    } catch (error) {
      console.log(error);
    }
    
    await this.checkSmsMessage()

  },
  methods: {
    /**
     * @vuese
     * emailVerify function
     */
    async emailVerify() {
      if (this.$route.query.uuid) {
        const payload = {
          uuid: this.$route.query.uuid,
          email: this.$route.query.email,
          code: this.$route.query.code,
        };
        await auth.emailVerify(payload).then((res) => {
          this.sucessMsg(res.data.message);
          localStorage.removeItem("massege");
          let alertBox = document.querySelector(".activeAccountMessage");
          if (alertBox) {
            alertBox.style.display = "none";
          }
          if (!this.smsStatus || this.smsStatus == false || !this.smsAvailability) {
            this.$router.push(
              {
                path: "/",
              },
              () => {
                this.$router.go(0);
              }
            );
          }
          // location.reload();
        });
      }
    },
    /**
     * @vuese
     * otpVerification function
     */
    otpVerification() {
      auth
        .verificationMobile(this.form)
        .then(() => {
          this.$router.push("/profile/account-information-b2b").catch(() => { });
          this.$store.dispatch("getUserInfo");
        })
        .catch((error) => {
          const err = Object.values(error)[2].data.message;
          this.massageErr = err;
        });
    },
    /**
     * @vuese
     * resendCode function
     */
    resendCode() {
      auth
        .resendCodeMobile()
        .then((res) => {
          this.sucessMsg(res.data.message);
        })
        .catch((error) => {
          const err = Object.values(error)[2].data.message;
          this.errMsg(err.message);
          if (error.response.data.code == 400 && !this.resend) {
            this.resendCodeAgain();
          }
        });
    },

    resendCodeAgain() {
      let payload = {
        mobile_number: this.buyerUserData.mobile_number,
        callback_url: `${this.mainDoamin}otp-verification`,
      };
      auth.resendCode(payload).then((res) => {
        if (res.status == 200) {
          if (!this.smsStatus || this.smsStatus == false || !this.smsAvailability) {
            this.$router.push(
              {
                path: "/",
              },
              () => {
                this.$router.go(0);
              }
            );
          } else {
            this.$router
              .push(
                {
                  path: "/otp-verification",
                  query: {
                    resend: true,
                  },
                },
                () => {
                  this.$router.go(0);
                }
              )
              .catch(() => { });
          }
        }
      });
    },

    /**
     * @vuese
     * emailChangingVerify function
     */
    async emailChangingVerify() {
      if (this.$route.query.uuid) {
        const payload = {
          uuid: this.$route.query.uuid,
          email: this.$route.query.email,
          code: this.$route.query.code,
        };
        await auth.emailVerify(payload).then((res) => {
          this.sucessMsg(res.data.message);
          localStorage.removeItem("massege");
          let alertBox = document.querySelector(".activeAccountMessage");
          if (alertBox) {
            alertBox.style.display = "none";
          }
          if (!this.smsStatus || this.smsStatus == false || !this.smsAvailability) {
            this.$router.push(
              {
                path: "/",
              },
              () => {
                this.$router.go(0);
              }
            );
          }

          // location.reload();
        });
      }
    },
    /**
     * @vuese
     * otpChangingVerification function
     */
    otpChangingVerification() {
      auth
        .otpChangingVerification(this.form)
        .then(() => {
          this.$router.push("/profile/account-information-b2b").catch(() => { });
          this.$store.dispatch("getUserInfo");
        })
        .catch((error) => {
          const err = Object.values(error)[2].data.message;
          this.massageErr = err;
        });
    },
    checkSmsMessage() {
      auth.getSiteImages().then((res) => {
        let result = res.data.items;
        for (const key in result) {
          if (result.hasOwnProperty.call(result, key)) {
            let element = result[key];

            if (element.key == "sms_status") {
              if (element.value == 1 || element.value == "1") {
                localStorage.setItem("sms_status", "available");
                this.smsAvailability = true;
              } else {
                localStorage.setItem("sms_status", "notAvailable");
                localStorage.removeItem("message");
                this.smsAvailability = false;
              }
            }
          }
        }
      });
    },
  },
  metaInfo: {
    title: "otp verify page",
    titleTemplate: "%s",
    htmlAttrs: {
      lang: `${localStorage.getItem("lang")}`,
      amp: true,
    },
  },
  watch: {
    smsAvailability(newVal) {
      if (newVal == false) {
        this.$router.push(
          {
            path: "/",
          },
          () => {
            this.$router.go(0);
          }
        );
      }
    }
  }
};
</script>

<style lang="scss" scoped>
/**
 * page style
*/
.otp-verification {
  text-align: center;

  h1 {
    color: black;
  }

  .otp-form {
    .block-header {
      // background: linear-gradient(135deg, #bf2718 0, #ea6153 100%) !important;
      background: $main-color;
      display: flex;
      padding: 12px;
      color: white;
      display: inline-block;
    }

    input {
      max-width: 50%;
      margin: auto;
    }

    .resend {
      transition: all 0.5s ease-in-out;
      cursor: pointer;

      &:hover {
        color: $main-color;
      }
    }
  }
}

.bg-main {
  background: $main-color !important;
  color: #fff !important;
}
</style>
